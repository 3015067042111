
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Here you can override individual files in the dataset. If a file is not provided, it will be substituted from the currently selected dataset. Learn more in the documentation: `}<a parentName="p" {...{
        "href": "https://docs.nextstrain.org/projects/nextclade/en/stable/user/input-files.html"
      }}>{`Input files`}</a>{`, `}<a parentName="p" {...{
        "href": "https://docs.nextstrain.org/projects/nextclade/en/stable/user/datasets.html"
      }}>{`Datasets`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;